<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/home/banner.png" alt="" />
    </div>

    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: orgPath }">{{
          breadcrumbName
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>营销推广</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="container">
      <div class="box">
        <div class="one_title">营销推广服务</div>
        <div class="two_title">
          多渠道多场景、客户一号触达、优化销售场景、提高商机转化
        </div>
        <div class="img_box">
          <img src="../../assets/img/market/market.png" alt="" />
        </div>
      </div>
    </div>

    <div class="container container_bg">
      <div class="box">
        <div class="one_title">服务亮点</div>
        <div class="bright_points_list">
          <div class="bright_points_item">
            <div class="ld_img">
              <img src="../../assets/img/market/ld1.png" alt="" />
            </div>
            <div class="ld_head">
              <div class="ld_title">体 验</div>
              <div class="ld_line"></div>
            </div>
            <div class="ld_content">
              <p>群发短信2.0</p>
              <p>用户体验感强</p>
            </div>
          </div>
          <div class="bright_points_item">
            <div class="ld_img">
              <img src="../../assets/img/market/ld1.png" alt="" />
            </div>
            <div class="ld_head">
              <div class="ld_title">营 销</div>
              <div class="ld_line"></div>
            </div>
            <div class="ld_content">
              <p>与客户进行互动式引导式</p>
              <p>营销业务客户群全覆盖</p>
            </div>
          </div>
          <div class="bright_points_item">
            <div class="ld_img">
              <img src="../../assets/img/market/ld1.png" alt="" />
            </div>
            <div class="ld_head">
              <div class="ld_title">精 准</div>
              <div class="ld_line"></div>
            </div>
            <div class="ld_content">
              <p>形式多样新颖有效解决信息</p>
              <p>传输过程中的丢失和弱化</p>
            </div>
          </div>
          <div class="bright_points_item">
            <div class="ld_img">
              <img src="../../assets/img/market/ld1.png" alt="" />
            </div>
            <div class="ld_head">
              <div class="ld_title">快 捷</div>
              <div class="ld_line"></div>
            </div>
            <div class="ld_content">
              <p>一号投递、一键深读</p>
              <p>一键办理安全快捷</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="box">
        <div class="one_title">业务场景</div>
        <div class="scene_list">
          <div class="scene_item">
            <div class="scene_item_circle"></div>
            <div class="scene_item_title">一般通知类</div>
            <div class="scene_item_p">
              产品到期、积分兑换、新业务上线、生日祝福
            </div>
            <div class="scene_item_experience" @click="handleApply">去体验</div>
          </div>

          <div class="scene_item">
            <div class="scene_item_circle"></div>
            <div class="scene_item_title">紧急通知类</div>
            <div class="scene_item_p">
              营业时间调整、业务暂停办理、紧急业务办理
            </div>
            <div class="scene_item_experience" @click="handleApply">去体验</div>
          </div>

          <div class="scene_item">
            <div class="scene_item_circle"></div>
            <div class="scene_item_title">内部管理</div>
            <div class="scene_item_p">会议、活动类通知</div>
            <div class="scene_item_experience" @click="handleApply">去体验</div>
          </div>

          <div class="scene_item">
            <div class="scene_item_circle"></div>
            <div class="scene_item_title">公共公益类</div>
            <div class="scene_item_p">
              <p>道路养护绕行通知</p>
              <p>限行通知、极端天气预警提示</p>
            </div>
            <div class="scene_item_experience" @click="handleApply">去体验</div>
          </div>
          <div class="scene_item">
            <div class="scene_item_circle"></div>
            <div class="scene_item_title">其他类</div>
            <div class="scene_item_p">
              账户通知、业务办理进程、还款通知、卡片到期短信验证码、异常登录/业务提示、数字存证
            </div>
            <div class="scene_item_experience" @click="handleApply">去体验</div>
          </div>
          <div class="scene_item">
            <div class="scene_item_circle"></div>
            <div class="scene_item_title">一键办理</div>
            <div class="scene_item_p">
              对账单下载、银企对账、客户身份信息更新、法人机构有效期更新、法人代表更换、企业营业范围更新
            </div>
            <div class="scene_item_experience" @click="handleApply">去体验</div>
          </div>
          <div class="scene_item">
            <div class="scene_item_circle"></div>
            <div class="scene_item_title">营销类</div>
            <div class="scene_item_p">
              客户激活、产品宣传、节日回馈、活动沙龙、节日祝福、满意度调查
            </div>
            <div class="scene_item_experience" @click="handleApply">去体验</div>
          </div>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
    </div>

    <div class="container container_bg">
      <div class="box">
        <div class="one_title">技术支持</div>
        <div class="bright_points_list">
          <div class="bright_points_item">
            <div class="ld_img">
              <img src="../../assets/img/market/ld1.png" alt="" />
            </div>
            <div class="ld_head">
              <div class="ld_title">闹钟+邮件</div>
            </div>
            <div class="ld_content">
              <p>形式多样、渠道丰富</p>
              <p>内容多彩呈现</p>
            </div>
          </div>
          <div class="line_img">
            <img src="../../assets/img/market/line.png" alt="" />
          </div>
          <div class="bright_points_item">
            <div class="ld_img">
              <img src="../../assets/img/market/ld1.png" alt="" />
            </div>
            <div class="ld_head">
              <div class="ld_title">操作便捷</div>
            </div>
            <div class="ld_content">
              <p>无需系统对接、数据批量</p>
              <p>导入、多种发送模式</p>
            </div>
          </div>
          <div class="line_img">
            <img src="../../assets/img/market/line.png" alt="" />
          </div>
          <div class="bright_points_item">
            <div class="ld_img">
              <img src="../../assets/img/market/ld1.png" alt="" />
            </div>
            <div class="ld_head">
              <div class="ld_title">信息安全</div>
            </div>
            <div class="ld_content">
              <p>深耕短信业务二十载</p>
              <p>专业口碑值得信赖</p>
            </div>
          </div>
          <div class="line_img">
            <img src="../../assets/img/market/line.png" alt="" />
          </div>
          <div class="bright_points_item">
            <div class="ld_img">
              <img src="../../assets/img/market/ld1.png" alt="" />
            </div>
            <div class="ld_head">
              <div class="ld_title">三网合一</div>
            </div>
            <div class="ld_content">
              <p>三大运营商长期合作</p>
              <p>即时获取状态报告</p>
            </div>
          </div>
        </div>
        <div class="ty_btn" @click="handleApply">去体验</div>
      </div>
    </div>

    <!-- 体验 -->
    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      formInline: {
        address: "太原",
        phone: "",
        acctName: "",
      },
      org: "",
      orgPath: "",
      breadcrumbName: "",

      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    getCode() {},
    onSubmit() {
      this.dialogVisible = true;
    },
    // 体验信息提交
    handleApply() {
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "24";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() {
    this.org = this.$route.query.org;
    if (this.org == "home") {
      this.orgPath = "/";
      this.breadcrumbName = "首页";
    } else {
      this.orgPath = "/digitalService";
      this.breadcrumbName = "数字化服务";
    }
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.container_title span {
  display: block;
  margin: 20px 0;
}

.one_title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.two_title {
  font-size: 18px;
  color: #949494;
  text-align: center;
  margin-top: 10px;
}

.img_box {
  width: 100%;
  height: 450px;
  margin-top: 50px;
}

.img_box img {
  display: block;
  width: 100%;
  height: 100%;
}

.bright_points_list {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}

.bright_points_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ld_img {
  width: 104px;
  height: 104px;
  margin: 0 auto;
}

.ld_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.ld_head {
  width: 100%;
  margin-top: 8px;
}

.ld_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

.ld_line {
  width: 64px;
  height: 3px;
  background: #055afe;
  border-radius: 2px;
  margin: 8px auto;
}

.ld_content {
  margin-top: 5px;
}

.ld_content p {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #000;
}

.scene_list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 56px;
}

.scene_item {
  position: relative;
  width: 266px;
  /* height: 153px; */
  background: rgba(251, 249, 252, 1);
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 22px 20px 45px;
}

.scene_list i {
  width: 266px;
}

.scene_item_circle {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 9px;
  height: 9px;
  background: #055afe;
  border-radius: 50%;
}

.scene_item_title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.scene_item_p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
  margin-top: 8px;
}

.scene_item_experience {
  position: absolute;
  right: 14px;
  bottom: 14px;
  width: 74px;
  height: 26px;
  border: 1px solid #055afe;
  border-radius: 2px;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: #055afe;
  cursor: pointer;
}

.line_img {
  width: 119px;
  height: 6px;
  margin-top: 50px;
}

.line_img img {
  display: block;
  width: 100%;
}

.ty_btn {
  width: 180px;
  height: 42px;
  line-height: 42px;
  background: #055afe;
  border-radius: 21px;
  margin: 30px auto;
  font-size: 20px;
  font-weight: bold;
  color: #fffefe;
  text-align: center;
  margin-top: 80px;
  cursor: pointer;
}

.dialog_title {
  width: 100%;
  height: auto;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: -17px;
}

.experience_flow {
  font-size: 24px;
  margin-bottom: 30px;
  margin-left: -17px;
}

.experience_flow span {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}

.flow_list {
  padding-left: 17px;
  width: 100%;
  height: 220px;
  background-image: linear-gradient(
    to bottom,
    #055afe 0%,
    #055afe 50%,
    transparent 50%
  );
  background-size: 2px 10px;
  background-repeat: repeat-y;
}

.flow_item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.flow_num {
  position: absolute;
  left: -34px;
  background: #fff;
  width: 34px;
  height: 34px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #055afe;
  color: #055afe;
  font-size: 26px;
}

.flow_msg {
  flex: 1;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  margin-left: 9px;
  letter-spacing: 2px;
}

.experience >>> .el-dialog--center .el-dialog__body {
  padding: 25px 60px 70px;
}

.qr_code {
  margin-top: 30px;
  overflow: hidden;
}

.qr_code_border {
  position: relative;
  float: left;
  width: 148px;
  height: 148px;
  border: 1px solid #055afe;
  padding: 5px;
  border-radius: 8px;
}

.qr_code_img {
  width: 100%;
  height: 100%;
}

.qr_code_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.qr_code_title {
  float: left;
  overflow: hidden;
  margin-top: 46px;
  margin-left: 30px;
}

.qr_icon {
  width: 46px;
  height: 46px;
  float: left;
}

.qr_title {
  float: left;
  font-size: 16px;
  margin-left: 12px;
}

.info_title_box {
  margin-bottom: 20px;
}

.info_title {
  font-size: 26px;
  font-weight: bold;
  color: #000;
  text-align: center;
}

.info_line {
  width: 30px;
  height: 3px;
  background: #055afe;
  margin: 0 auto;
}

.btn_sub {
  width: 393px;
  height: 62px;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-indent: 36px;
  letter-spacing: 36px;
  margin: 0 auto;
  margin-top: 30px;
  background: #055afe;
  /* border: 1px solid rgba(220, 220, 220, 1); */
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;
}
.login-code-img {
  height: 38px;
}
.login-code img {
  cursor: pointer;
  vertical-align: middle;
}

.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
